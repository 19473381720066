import { Link } from "gatsby"
import React from "react"
import InnerHeader from "../../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import * as forOurDealersStyles from "./forOurDealers.module.scss"

const ForOurDealers = ({ pageContext, location }) => {
  const pageTitle = "For Our Dealers"

  return (
    <Layout>
      <Seo title={pageTitle} />
      <InnerHeader
        pageTitle={pageTitle}
        pageContext={pageContext}
        location={location}
      />
      <div
        className={`inner-container-alt inner-y-padding ${forOurDealersStyles.contentWrapper}`}
      >
        <div className={forOurDealersStyles.committed}>
          <h2
            className="h5"
            style={{
              maxWidth: "50rem",
              marginBottom: "3rem",
              lineHeight: "1.3",
            }}
          >
            We are{" "}
            <em style={{ color: "#9d5918" }}>
              committed to service excellence
            </em>
            : we listen intently, provide superior solutions quickly, and are
            easy to do business with. Our{" "}
            <strong style={{ color: "#9d5918" }}>integrity</strong> and{" "}
            <strong>reliability</strong> are paramount.
          </h2>
        </div>
        <div className={forOurDealersStyles.resources}>
          <h3>Dealer Resources</h3>
          <ul>
            <li>
              <Link
                to="/customer-resources/marketing-resources"
                className="biggerLink"
                title="Marketing Resources"
              >
                Marketing Resources
              </Link>
            </li>
            <li>
              <Link
                to="/customer-resources/for-our-dealers/dealer-locator-form"
                className="biggerLink"
                title="Dealer Locator Form"
              >
                Dealer Locator Addition/Deletion Form
              </Link>
            </li>
            <li>
              <Link
                to="/customer-resources/for-our-dealers/social-media-promotion-request"
                className="biggerLink"
                title="Social Media Promotion Request"
              >
                Social Media Promotion Request Form
              </Link>
            </li>
            <li>
              <Link
                to="/customer-resources/for-our-dealers/digital-dealer-contact-form"
                className="biggerLink"
                title="Digital Dealer Contact Form"
              >
                Digital Dealer Contact Form
              </Link>
            </li>
            <li>
              <Link
                to="/customer-resources/for-our-dealers/business-profile-requirements"
                className="biggerLink"
                title="Business Profile Requirements"
              >
                Business Profile Requirements
              </Link>
            </li>
            <li>
              <a
                href="https://rbconx.com/"
                className="biggerLink"
                target="__blank"
                rel="noopener noreferrer"
                title="Rocky Brands ConX"
              >
                RBConx.com
              </a>
            </li>
            <li>
              <a
                href="https://images.rockybrands.com/"
                className="biggerLink"
                target="__blank"
                rel="noopener noreferrer"
                title="Rocky Brands Image Library"
              >
                Rocky Brands Image Library
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  )
}

export default ForOurDealers
